import React from "react"
import Layout from "components/layout"
import { graphql } from "gatsby"
import SEO from "components/seo"
import { HomeTemplate } from "components/Templates/HomeTemplate"

const Home = ({ data }) => {
  const { seo, featuredImage } = data.page

  return (
    <Layout variant="home" pageContext={data.page}>
      <SEO
        title={seo.title}
        image={featuredImage?.node?.sourceUrl}
        description={seo.metaDesc}
      />
      <HomeTemplate data={data.page} />
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      seo {
        title
        metaDesc
      }
      featuredImage {
        node {
          sourceUrl
          srcSet
        }
      }
      headerSettings {
        customPageTitle
        headerText
        headerPage {
          ... on WpPage {
            title
            uri
          }
        }
      }
      avLocations {
        locHeading
        locDescription
        locItems {
          label
          image {
            sourceUrl
            srcSet
          }
          linkPage {
            ... on WpPage {
              uri
            }
          }
        }
      }
      avStatistics {
        statExcerpt
        statHeading
        statItems {
          statistic
          description
        }
      }
      avSolutionTiles {
        stExcerpt
        stHeading
        stViewAll {
          ... on WpPage {
            uri
          }
        }
        stItems {
          content
          icon
          page {
            ... on WpPage {
              title
              uri
            }
          }
        }
      }

      avTestimonials {
        tsItems {
          author
          image {
            sourceUrl
            srcSet
          }
          testimonial
        }
      }

      avCarousel {
        fcHeading
        fcItems {
          heading
          excerpt
          heading
          page {
            ... on WpPage {
              uri
            }
          }
          image {
            sourceUrl
            srcSet
          }
        }
      }
      avFeatureTiles {
        ftHeading
        ftMessage
        ftTiles {
          content
          icon
          heading
        }
        ftShowVideo
        ftVideo {
          url
          thumbnailImage {
            sourceUrl
          }
        }
      }
    }
  }
`
